<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant="navState"
    app
    mini-variant-width="54"
    permanent
    :touchless="true"
    class="main-nav"
    width="250"
  >
    <div class="logo-container pa-2">
      <v-btn
        aria-label="Navigation"
        icon
        @click.stop="toggleNav"
      >
        <v-icon>{{ drawerIcon }}</v-icon>
      </v-btn>

      <img
        src="../../assets/images/oao_logo_blue.png"
        style="margin-left: 12px;"
        aria-label="OAO logo"
      >
    </div>

    <v-divider />

    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <a
          href="javascript:;"
          :class="`logged-in-user-info cursor-pointer ${navState ? 'ml-2' : 'ml-3'}`"
          v-on="on"
        >
          <v-avatar>
            <!-- The referrerPolicy="no-referrer" set on the img tag mitigates against errors loading images from Google's servers. -->
            <img
              :src="user.photoURL"
              alt="Avatar"
              referrerPolicy="no-referrer"
            >
          </v-avatar>

          <div class="user-details">
            <div class="user-name">
              {{ user.displayName }}
            </div>

            <div
              class="user-email"
            >
              {{ user.email }}
            </div>

            <div
              v-if="inAliasMode"
              class="user-email"
            >
              <span
                class="user-email"
              >
                as
              </span>
              <span class="user-email">
                {{ aliasEmail }}
              </span>
            </div>

            <div
              v-if="inDemoMode"
              class="user-email font-weight-medium primary--text"
            >
              Demo Mode On
            </div>
          </div>
        </a>
      </template>

      <v-list>
        <v-list-item
          v-if="hasPermissionForSettings"
          class="caption"
          @click="displaySettingsModal"
        >
          <v-list-item-title class="coolgray--text">
            <v-icon
              class="pr-2 coolgray--text"
              small
            >
              format_paint
            </v-icon>
            <span>Settings</span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="signOut">
          <v-list-item-title class="toasted--text">
            <v-icon
              class="pr-2 toasted--text"
              small
            >
              power_settings_new
            </v-icon>
            <span>Sign Out</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-divider />

    <v-list :class="`pt-0 menu-items ${navState ? 'pl-0' : 'pl-1'}`">
      <div
        v-for="(nav, navIndex) in navigation"
        :key="navIndex"
      >
        <v-divider
          v-if="nav.divider === 'pre'"
        />

        <v-tooltip
          v-if="navState"
          right
        >
          <template v-slot:activator="{ on }">
            <v-list-item
              :class="`menu-item ${navState ? 'pl-4' : 'pl-4'}`"
              active-class="router-active-link"
              @click="selectSearchItem(nav)"
              v-on="on"
            >
              <v-list-item-action class="mr-0 justify-center">
                <img
                  :src="nav.image"
                  :width="iconWidth"
                >
              </v-list-item-action>

              <v-list-item-content :class="navState ? 'ml-1' : 'ml-3'">
                <v-list-item-title>
                  <span class="subtitle-2 font-weight-medium">{{ nav.title }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          {{ nav.tooltip }}
        </v-tooltip>

        <v-list-item
          v-else
          :class="`menu-item ${navState ? 'pl-4' : 'pl-4'}`"
          active-class="router-active-link"
          @click="selectSearchItem(nav)"
        >
          <v-list-item-action class="mr-0 justify-center">
            <img
              :src="nav.image"
              :width="iconWidth"
            >
          </v-list-item-action>

          <v-list-item-content :class="navState ? 'ml-1' : 'ml-3'">
            <v-list-item-title>
              <span class="subtitle-2 font-weight-medium">{{ nav.title }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div class="sub-items-container">
          <router-link
            v-for="subNav in nav.subItems"
            :key="subNav.title"
            class="sub-item-link"
            :to="subNav.link"
          >
            {{ subNav.title }}
          </router-link>
        </div>

        <v-divider
          v-if="nav.divider === 'post'"
        />
      </div>
    </v-list>

    <!-- eslint-disable-next-line vue/html-self-closing -->
    <v-spacer></v-spacer>

    <v-footer
      v-if="drawer"
      class="nav-footer pa-0"
      color="white"
      absolute
    >
      <v-col class="pa-0">
        <v-divider
          class="mx-0 mb-3"
        />

        <!-- <a
          href="/docs"
          :class="`help-icon ${navState ? 'pl-1' : 'pl-4'} pr-2`"
        >
          <v-icon>folder</v-icon>
        </a> -->

        <!-- <div class="links-container pl-2"> -->
        <div class="links-container pl-6 pb-3">
          <div
            class="copywrite"
            to="/docs/privacy-policy"
          >
            &copy; {{ new Date().getFullYear() }}, OAO
          </div>
          <div
            class="caption"
          >
            {{ version }}
          </div>
        </div>
      </v-col>
    </v-footer>
  </v-navigation-drawer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import layoutActionNames from '../store/actionNames'

const {
  mapGetters: mapAuthGetters,
  mapActions: mapAuthActions,
  mapState: mapAuthState,
} = createNamespacedHelpers('auth')
const { mapState, mapMutations } = createNamespacedHelpers('layout')
const { mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'Navigation',
  data() {
    return {
      drawer: true,
      iconWidth: '18px',
    }
  },
  computed: {
    ...mapAuthState({
      demoMode: (state) => state.demoMode,
      user: (state) => state.user,
    }),
    ...mapAuthGetters([
      'aliasEmail',
      'isActiveUser',
      'inAliasMode',
      'inDemoMode',
      'permissions',
    ]),
    ...mapToolsGetters([
      'getReportKey',
    ]),
    ...mapToolsState({
      navigation: (state) => state.navigation,
      searchResults: (state) => state.searchResults,
    }),
    ...mapState({
      icons: (state) => state.icons,
      isSettingsModalVisible: (state) => state.isSettingsModalVisible,
      navCollapsed: (state) => state.navCollapsed,
      version: (state) => state.version,
    }),
    showSettingsModal: {
      get() {
        return this.isSettingsModalVisible
      },
      set(value) {
        this.$store.commit('layout/isSettingsModalVisible', value, { root: true })
      },
    },
    navState: {
      get() {
        return this.navCollapsed
      },
      set() {
        this.$store.commit('layout/navCollapsed')
      },
    },
    drawerIcon() {
      return this.navCollapsed ? 'chevron_right' : 'chevron_left'
    },
    docRoute() {
      const { icons: { navigation: navIcons } } = this

      return {
        title: 'User Docs',
        image: navIcons.docs,
        link: '/docs',
        active: false,
        subItems: false,
      }
    },
    hasPermissionForSettings() {
      const { isActiveUser } = this

      return isActiveUser
    },
  },
  methods: {
    ...mapAuthActions(['signOut']),
    ...mapMutations({
      toggleNav: layoutActionNames.TOGGLE_NAV,
    }),
    displaySettingsModal() {
      this.showSettingsModal = true
    },
    async findSelectedSearch(nav) {
      const { params, reportKey } = nav
      const { searchResults } = this
      const { reportName, reportId, subId } = params

      const searchEndpoint = searchResults.endpoints[reportKey]
      const searchCollection = await searchEndpoint.items.list

      return this.$store.dispatch('tools/findSelectedSearch', { reportId, reportName, searchCollection, subId })
    },
    async selectSearchItem(nav) {
      const selection = await this.findSelectedSearch(nav)

      await this.$store.dispatch('tools/selectSearchItem', selection)

      return this.$store.dispatch('tools/renderMainContent', this.$route)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.main-nav {
  display: flex;
  flex-direction: column;
}

.v-divider {
  border-color: $color-monochrome-icewhite;
}

.logo-container {
  display: flex;
  align-items: center;
  padding: 15px;

  button {
    margin: 0;
  }

  img {
    height: 16px;
  }
}

.logged-in-user-info {
  display: flex;
  align-items: center;

  ::v-deep.v-avatar {
    height: 30px !important;
    min-width: auto !important;
    width: 30px !important;

    transition-duration: 0.2s;
    transition-property: height, width, transform;

    box-shadow: 0 2px 6px 3px rgba(165, 177, 185, 0.3);
  }

  .user-details {
    padding: 15px 0 15px 15px;
    overflow: hidden;

    .user-name {
      color: $color-monochrome-darkestblue;
      font-size: $size-regular;
      font-weight: bold;
      letter-spacing: 0.43px;
      line-height: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .user-email {
      color: $color-monochrome-gray;
      font-size: $size-small;
      font-weight: 500;
      letter-spacing: 0.36px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.menu-items {
  .menu-item {
    color: $color-monochrome-darkestblue;

    ::v-deep.v-list__tile {
      height: inherit;
    }
  }
}

.sub-items-container {
  display: flex;
  flex-direction: column;
  margin-left: 75px;

  .sub-item-link {
    padding-top: 9px;
    color: $color-monochrome-gray;
    font-size: $size-regular;
    letter-spacing: 0.36px;
    line-height: 18px;
    &:hover {
      color: #00aed6;
    }

    &.router-link-active {
      font-weight: bolder;
    }
  }
}

::v-deep.router-active-link {
  background-color: $color-offwhite;
  color: inherit;
}

.nav-footer {
  .help-icon .v-icon {
    font-size: 33px;
    color: $color-monochrome-darkestblue;
  }

  .links-container {
    flex: 1;
    white-space: nowrap;

    .copywrite {
      color: $color-monochrome-darkestblue;
      font-size: $size-small;
      font-weight: 500;
      letter-spacing: 0.36px;
      line-height: 18px;
    }
  }

  .footer-links {
    display: flex;
    justify-content: flex-start;
    padding-right: 48px;

    color: $color-monochrome-gray;
    font-size: $size-small;
    font-weight: 500;
    letter-spacing: 0.36px;
    line-height: 20px;

    a {
      color: inherit;
    }

    .bullet-divider {
      margin: 0 1ch;
    }
  }
}

.v-navigation-drawer {
  box-shadow: 0 2px 6px 0 rgba(0, 148, 255, 0.21);
}

.v-navigation-drawer--mini-variant {
  box-shadow: none;

  .logo-container {
    justify-content: start;

    img {
      display: none;
    }
  }

  .logged-in-user-info {
    padding: 9px 0;

    ::v-deep.v-avatar {
      height: 30px !important;
      width: 30px !important;

      transform: translateX(4px);
    }

    .user-details {
      display: none;
    }
  }

  .sub-items-container {
    display: none;
  }

  .nav-footer {
    .help-icon .v-icon {
      margin: 0;
      transform: translateX(8px);
    }

    .links-container {
      display: none;
    }
  }
}
</style>
